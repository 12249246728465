import React, { useRef, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import {
  DivChat,
  StreamContainer,
  DivMain,
  DivQuestion,
  DivTop,
  DivQuestionsBottom,
  Video,
  CardQuestion,
  DivMessages,
  Message,
  Button,
  DivRow,
} from "./styles";
import { getQuestion } from "../../services/room";
import { useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import Toast from "../../components/Toast";

const currentUrl = window.location.href;

export let chatUrl = "";

if (currentUrl.includes(".com.br")) {
  chatUrl = "https://api.quizcash.com.br/";
} else if (currentUrl.includes("devaribox.co")) {
  chatUrl = "https://quiz-api.devaribox.co/";
} else if (currentUrl.includes(":300")) {
  //change this if needed for local development
  chatUrl = "https://quiz-api.devaribox.co";
  // chatUrl = "http://192.168.1.9:3010";
  // chatUrl = "http://localhost:3010";
}
const socket: Socket = io(chatUrl);

interface IState {
  item: IRoom;
}
interface IRoom {
  Classe: IClass;
  Modalidade: IModality;
  atualizadoEm: string;
  criadoEm: string;
  dataInicio: string;
  excluido: boolean;
  id: string;
  idClasse: string;
  idModalidadeSala: string;
  nivel: string;
  nome: string;
  valorIngresso: number;
}

interface IOffer {
  data: any;
  type: string;
  clientId: string;
}

interface IModality {
  id: string;
  nome: string;
}

interface IClass {
  id: string;
  nome: string;
}

interface IQuality {
  label: string;
  width: number;
  height: number;
}

interface IChat {
  id: number;
  name: string;
  text: string;
  color: string;
}

const Chat: IChat[] = [
  {
    id: 1,
    text: "Lorem ipsum dolor sit.",
    name: "Fulano de Tal",
    color: "#FF0000",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit.",
    name: "Rafael Alves",
    color: "#4545ee",
  },
  {
    id: 3,
    text: "Lorem ipsum dolor sit.",
    name: "Alice da Silva",
    color: "#FFB6C1",
  },
  {
    id: 4,
    text: "Lorem ipsum dolor sit.",
    name: "Thales Roberto",
    color: "#DC143C",
  },
  {
    id: 5,
    text: "Lorem ipsum dolor sit.",
    name: "Fernanda Costa",
    color: "#FF69B4",
  },
  {
    id: 6,
    text: "Lorem ipsum dolor sit.",
    name: "Fulano de Tal",
    color: "#D2691E",
  },
  {
    id: 7,
    text: "Lorem ipsum dolor sit.",
    name: "Rafael Alves",
    color: "#4545ee",
  },
  {
    id: 8,
    text: "Lorem ipsum dolor sit.",
    name: "Alice da Silva",
    color: "#7FFFD4",
  },
  {
    id: 9,
    text: "Lorem ipsum dolor sit.",
    name: "Thales Roberto",
    color: "#9400D3",
  },
  {
    id: 10,
    text: "Lorem ipsum dolor sit.",
    name: "Fernanda Costa",
    color: "#FFA500",
  },
  {
    id: 11,
    text: "Lorem ipsum dolor sit.",
    name: "Alice da Silva",
    color: "#1E90FF",
  },
  {
    id: 12,
    text: "Lorem ipsum dolor sit.",
    name: "Thales Roberto",
    color: "#008000",
  },
  {
    id: 13,
    text: "Lorem ipsum dolor sit.",
    name: "Fernanda Costa",
    color: "#FFFF00",
  },
];

const servers = {
  iceServers: [
    { urls: "stun:stun.l.google.com:19302" },
    { urls: "stun:stun1.l.google.com:19302" },
    { urls: "stun:stun2.l.google.com:19302" },
    { urls: "stun:stun3.l.google.com:19302" },
    { urls: "stun:stun4.l.google.com:19302" },
    { urls: "stun:stun.sipgate.net:2083" },
    { urls: "stun:stun.ekiga.net:2083" },
    {
      urls: "turn:openrelay.metered.ca:80",
      credential: "openrelayproject", 
      username: "openrelayproject"
    }
    // {
    //   urls: 'turn:turn.devari.com.br:3478',
    //   credential: 'c39faea3cdd71e6aa569479bade87a41db6fcd91d34cf69d21e94254dba3bfed',
    //   username: 'devari'
    // },
  ],
};

const StreamRoom: React.FC<{}> = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<HTMLVideoElement | null>(null);
  const streamMedia = useRef<MediaStream | null>(null);
  const timeClock = useRef<NodeJS.Timeout | null>(null);
  const [qualityOptions, setQualityOptions] = useState<Array<IQuality>>([]);
  const location = useLocation();
  const [idRoom, setIdRoom] = useState<string>("");
  const [contador, setContador] = useState<string>("00:00");
  const [localStream, setLocalStream] = useState<any>(null);
  const [questions, setQuestions] = useState<Array<IRoom>>([]);
  const [questionsSelected, setQuestionsSelected] = useState<IRoom>();
  const [questionReserve, setQuestionReserve] = useState<IRoom>();
  const [emitVideo, setEmitVideo] = useState<boolean>(false);
  const [room, setRoom] = useState<IRoom>();
  const [sendQuestion, setSendQuestion] = useState<IRoom | null>(null);
  let localStreamer: any;
  const coresHex: string[] = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#FFA500",
    "#008000",
    "#800080",
    "#FFC0CB",
    "#FF4500",
    "#7FFF00",
    "#9400D3",
    "#DC143C",
    "#FFD700",
    "#2E8B57",
    "#4B0082",
    "#ADFF2F",
    "#FF69B4",
    "#D2691E",
    "#00BFFF",
    "#1E90FF",
    "#7FFFD4",
    "#FF7F50",
    "#BA55D3",
    "#9932CC",
    "#FF1493",
    "#FF8C00",
    "#7CFC00",
    "#FFB6C1",
    "#FFDAB9",
  ];
  var listPC: any = {}

  useEffect(() => {
    return () => {
      console.log("Sair da sala")
      if (videoRef.current && videoRef.current.srcObject)
        videoRef.current.removeAttribute("srcObject");

      if (localStreamer) {
        localStreamer.getTracks().forEach((track: any) => {
          track.stop();
        });
        localStreamer = null;
      }

      if (localStreamer) localStreamer.stop();

      if (room) {
        socket.emit("leave-room", { idSala: room.id });
      }
    };
  }, []);

  useEffect(() => {
    const { item }: IState = location.state as IState;
    if (item) {
      console.log("entrando na sala");
      const { id }: IRoom = { ...item };
      socket.emit("join-room", { idSala: id });
    }
  }, []);

  useEffect(() => {
    if (sendQuestion && idRoom) {
      socket.emit("question", {
        idSala: idRoom,
        idPergunta: sendQuestion.id,
        nivel: sendQuestion.nivel,
      });
      setSendQuestion(null);
    }
  }, [sendQuestion]);

  useEffect(() => {
    if (location && location.state) {
      const { item }: IState = location.state as IState;
      if (item) {
        const { idModalidadeSala }: IRoom = { ...item };
        getQuestions(idModalidadeSala);
        setRoom({ ...item });
      }
    }
  }, []);

  useEffect(() => {
    function scrollToBottom() {
      const chatContainer = document.getElementById("chat-container");

      if (chatContainer) {
        const scrollHeight = chatContainer.scrollHeight;
        const height = chatContainer.clientHeight;
        const maxScrollTop = scrollHeight - height;
        chatContainer.scrollTo({
          top: maxScrollTop,
          behavior: "smooth",
        });
      }
    }

    scrollToBottom();
  }, []);

  useEffect(() => {
    if (questionsSelected) {
      var duration = 60 * 0.2; // Converter para segundos
      startTimer(duration); // iniciando o timer
    }
  }, [questionsSelected]);

  useEffect(() => {
    if (contador === "00:00" && questionReserve) {
      handleCardQuestion(questionReserve);
      var duration = 60 * 0.2; // Converter para segundos
      startTimer(duration); // iniciando o timer
    }
  }, [contador, questionReserve]);

  const setupWebRTC = async () => {
    const { item }: IState = location.state as IState;
    setEmitVideo(true);
    // // Obtém o fluxo de vídeo da câmera
    const localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    // Obtém o objeto MediaStreamTrack da câmera
    const cameraTrack = localStream.getVideoTracks()[0];

    // Obtém as capacidades da câmera
    const capabilities = cameraTrack.getCapabilities();

    // Obtém as faixas de largura e altura suportadas pela câmera
    const maxWidth = capabilities.width!.max;
    const maxHeight = capabilities.height!.max;

    // Define os tamanhos padronizados que você deseja gerar
    const standardSizes = [
      { label: "4k", width: 3840, height: 2160 }, // 4K
      { label: "1440p", width: 2560, height: 1440 }, // 1440p
      { label: "1080p", width: 1920, height: 1080 }, // 1080p
      { label: "900p", width: 1600, height: 900 }, // 900p
      { label: "720p", width: 1280, height: 720 }, // 720p
      { label: "540p", width: 960, height: 540 }, // 540p
      { label: "360p", width: 640, height: 360 }, // 360p
      // Adicione outros tamanhos padronizados de acordo com suas necessidades
    ];

    // Cria um array de objetos que representam as opções de resolução compatíveis com a câmera
    const resolutionOptions = standardSizes.filter(
      (size) => size.width <= maxWidth! && size.height <= maxHeight!
    );

    setQualityOptions(resolutionOptions);
    const remoteStream = new MediaStream();

    if (videoRef.current) videoRef.current.srcObject = localStream;
    // if (streamRef.current) streamRef.current.srcObject = remoteStream;
    if (localStreamer) {
      localStreamer = new MediaRecorder(localStream);
    }


    socket.on("ask-offer", async (message) => {
      console.log("cliente ", message, " pediu conexao");
      if(message !== socket.id){
        const _pc = new RTCPeerConnection(servers);

        localStream.getTracks().forEach((track) => {
          _pc.addTrack(track, localStream);
        });
    
        // E MESMO NECESSARIO ?
        _pc.ontrack = (event: RTCTrackEvent) => {
          event.streams[0].getTracks().forEach((track) => {
            remoteStream.addTrack(track);
          });
        };

        _pc.onicecandidate = (event: any) => {
          if (event.candidate) {
            socket.emit("new-connection", {
              idSala: item.id,
              data: event.candidate.toJSON(),
              type: "ice",
              idUserRecipe: message
            });
          }
        };
    
        const offerDescription = await _pc.createOffer();
        await _pc.setLocalDescription(offerDescription);

        // socket.emit("broadcast-video", {
        //   idSala: item.id,
        //   data: offerDescription,
        //   type: "offer",
        //   client: message
        // });

        socket.emit("new-connection", {
          idUserRecipe: message,
          data: {data: _pc.localDescription, type: "offer"},
        });

        listPC[message] = _pc;
      }
    })

    // configurando listner para ouvir o anwser
    socket.on("admin-broadcast-video", (message) => {
      if (message) readMessageVideo(message);
    });
  };

  const readMessageVideo = async (dataOffer: IOffer) => {
    const clientPC = listPC[dataOffer.clientId]; //acessa o peer do cliente que mandou a resposta
    console.log("configurando ", dataOffer.type, dataOffer.clientId);
    if (dataOffer.type === "answer") {
      if (!clientPC.currentRemoteDescription) {
        const answerDescription = new RTCSessionDescription(dataOffer.data);
        clientPC.setRemoteDescription(answerDescription);
      }
    } else if (dataOffer.type === "ice") {
      const candidate = new RTCIceCandidate(dataOffer.data);
      clientPC.addIceCandidate(candidate);
    }
  };

  const sendVideo = async (candidate?: RTCIceCandidate) => {
    // if (room) {
    //   if (candidate) {
    //     console.log("candidate");
    //     socket.emit("broadcast-video", {
    //       idSala: room.id,
    //       offer: candidate,
    //     });
    //   } else {
    //     pc.createOffer({
    //       offerToReceiveAudio: 1,
    //       offerToReceiveVideo: 1,
    //     })
    //       .then((offer: any) => pc.setLocalDescription(offer))
    //       .then(() => {
    //         console.log(pc.localDescription);
    //         socket.emit("broadcast-video", {
    //           idSala: room.id,
    //           offer: pc.localDescription,
    //         });
    //       })
    //       .catch((error: any) => {
    //         console.error("Offer error: ", error);
    //       });
    //   }
    // }
  };

  function startTimer(duration: number): void {
    let timer = duration;
    let minutes: string;
    let seconds: string;

    timeClock.current = setInterval(function () {
      minutes = String(parseInt(String(timer / 60), 10));
      seconds = String(parseInt(String(timer % 60), 10));
      minutes = +minutes < 10 ? "0" + minutes : minutes;
      seconds = +seconds < 10 ? "0" + seconds : seconds;
      setContador(minutes + ":" + seconds);
      if (--timer < 0) {
        timer = duration;
        if (timeClock.current !== null) {
          // Verifique se timeClock.current não é nulo
          clearTimeout(timeClock.current);
          timeClock.current = null;
        }
      }
    }, 1000);
  }

  const getQuestions = async (id: string) => {
    try {
      const result = await getQuestion(id);
      if (result) {
        setQuestions(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  // Função para selecionar cor aleatória em hexadecimal
  function selectRandomHexColor(): string {
    const corHex: string =
      coresHex[Math.floor(Math.random() * coresHex.length)];
    return corHex;
  }

  const handleQualityChange = async (option: {
    label: string;
    width: number;
    height: number;
  }) => {
    // if (streamRef.current && videoRef.current) {
    //   const constraints = {
    //     ...streamRef.current.getVideoTracks()[0].getSettings(),
    //     width: { ideal: option.width },
    //     height: { ideal: option.height },
    //   };
    //   const newStream = await navigator.mediaDevices.getUserMedia({
    //     video: constraints,
    //   });
    //   videoRef.current.srcObject = newStream;
    //   streamRef.current = newStream;
    // }
  };

  const endTransmition = () => {
    // if (room) {
    //   socket.emit("close-broadcast", {
    //     idSala: room.id,
    //   });
    // }

    // setEmitVideo(false);
    // pc.close();
    // console.log(localStreamer);

    // // localStreamer.getTracks().forEach((track: any) => {
    // //   track.stop();
    // // });
    // // localStreamer = null;

    // if (!socket.hasListeners("broadcast-video")) {
    //   setTimeout(() => {
    //     socket.disconnect();
    //   }, 100);
    // }
    // socket.removeAllListeners();
    // socket.off("broadcast-video");
  };

  const handleCardQuestion = (card: IRoom) => {
    if (!emitVideo)
      Toast.show(
        "Inicie a transmissão para poder enviar as perguntas",
        "error"
      );
    else {
      if (contador === "00:00") {
        const newQuestions = [...questions];

        const filterQuestions = newQuestions.filter(
          (item: any) => item.id !== card.id
        );

        setSendQuestion(card);
        setQuestions(filterQuestions);
        setQuestionsSelected(card);
      } else {
        setQuestionReserve(card);
      }
    }
  };

  return (
    <StreamContainer>
      {!emitVideo ? (
        <Button onClick={() => setupWebRTC()}>Iniciar Transmissão</Button>
      ) : (
        <Button onClick={() => endTransmition()}>Encerrar Transmissão</Button>
      )}
      <DivRow>
        <DivMain>
          <DivTop>
            <Video>
              <video autoPlay ref={videoRef}></video>
              <DivQuestion>
                <div>
                  <h2>Pergunta</h2>
                  <p>{contador}</p>
                </div>
                {questionsSelected ? (
                  <CardQuestion>
                    <label htmlFor="">
                      Pergunta: <span>{questionsSelected?.nome}</span>
                    </label>
                    <label htmlFor="">
                      Nível: <span>{questionsSelected?.nivel}</span>
                    </label>
                    <label htmlFor="">
                      Modalidade:{" "}
                      <span>{questionsSelected?.Modalidade.nome}</span>
                    </label>
                  </CardQuestion>
                ) : null}
              </DivQuestion>
            </Video>
            <div style={{ display: "flex", marginTop: 4, marginBottom: 4 }}>
              <h3>Opções de qualidade de vídeo:</h3>
              {qualityOptions.map((option) => (
                <button
                  key={option.label}
                  onClick={() => handleQualityChange(option)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </DivTop>
          <DivQuestionsBottom>
            <h2>Perguntas</h2>
            <div>
              {questions.length > 0
                ? questions.map((item: IRoom) => (
                    <CardQuestion
                      key={item.id}
                      onClick={() => handleCardQuestion(item)}
                    >
                      <label htmlFor="">
                        Pergunta: <span>{item.nome}</span>
                      </label>
                      <label htmlFor="">
                        Nível: <span>{item.nivel}</span>
                      </label>
                      <label htmlFor="">
                        Modalidade: <span>{item.Modalidade.nome}</span>
                      </label>
                    </CardQuestion>
                  ))
                : null}
            </div>
          </DivQuestionsBottom>
        </DivMain>
        <DivChat>
          <h2>CHAT DA TRANSMISSÃO</h2>
          <DivMessages id="chat-container">
            {Chat.map((item: any) => (
              <Message
                myUser={item.name === "Rafael Alves" ? true : false}
                color={item.color}
                key={item.id}
              >
                <h5>{item.name}</h5>
                <p>{item.text}</p>
              </Message>
            ))}
          </DivMessages>
        </DivChat>
      </DivRow>
    </StreamContainer>
  );
};

export default StreamRoom;
