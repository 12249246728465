import provider from "./config";

export const getQuestion = async (id: string) => {
	try {
		const response = await provider.get("tabela/", {
			params: {
				classe: "Perguntas",
				idModalidade: id
			}
		})

		return response.data
	} catch (err: any) {
		throw Error("Ocorreu um erro ao tentar listar as perguntas")
	}
}