enum AuthRoutes {
	home = '/',
	classes_explorer = '/classes-explorer',
	users = '/permissoes-e-grupos',
	sales_order = '/pedidos-de-saida/inserir',
	edit_sales_order = '/pedidos-de-saida/resultado/editar/:id',
	service_order = '/pedidos-de-servico/inserir',
	purchase_order = '/pedidos-de-entrada/inserir',
	edit_purchase_order = '/pedidos-de-entrada/resultado/editar/:id',
	purchase_service_order = '/pedidos-de-compra-de-servico/inserir',
	selling_service_order = '/pedidos-de-venda-de-servico/inserir',
	result_sales_order = '/pedidos-de-saida/resultado',
	import_providers = '/importar-fornecedores',
	import_products = '/importar-produtos',
	order_report = '/relatorio-pedidos-de-saida',
	result_selling_service_order = '/pedidos-de-venda-de-servico/resultado',
	result_purchase_service_order = '/pedidos-de-compra-de-servico/resultado',
	result_purchase_order = '/pedidos-de-entrada/resultado',
	result_pull_order = '/baixa-de-pedido/resultado',
	mirror_sales_order = '/pedidos-de-saida/resultado/espelho-pedido/:id',
	mirror_purchase_order = '/pedidos-de-entrada/resultado/espelho-pedido/:id',
	mirror_purchase_service_order = '/pedidos-de-compra-de-servico/resultado/espelho-pedido/:id',
	mirror_selling_service_order = '/pedidos-de-venda-de-servico/resultado/espelho-pedido/:id',
	order_down = '/baixa-do-pedido',
	light_release = '/lancamento-financeiro',
	title = '/titulos',
	request = '/requisicoes',
	request_result = '/requisicoes/resultado',
	accounting_plan = '/plano-de-contas',
	accounting_entry = '/lancamento-contabil',
	bank_reconciliation = '/conciliacao-bancaria',
	inventory = '/inventario',
	stock_balance = '/saldo-estoque',
	stock_balance_report = '/saldo-estoque/resultado',
	classes_report = '/listagem-classes/resultado',
	classes_report_search = '/listagem-classes',
	extract_balance = '/extrato-estoque',
	stock_extract_balance_report = '/extrato-estoque/resultado',
	employee_file = '/funcionarios/ficha/:id',
	providers_report = '/relatorio-fornecedores',
	result_providers_report = '/relatorio-fornecedores/resultado',
	conductors_report = '/relatorio-motoristas',
	result_conductors_report = '/relatorio-motoristas/resultado',
	vehicle_report = '/relatorio-veiculos',
	result_vehicle_report = '/relatorio-veiculos/resultado',
	vehicle_supply_report = '/relatorio-abastecimentos',
	result_vehicle_supply_report = '/relatorio-abastecimentos/resultado',
	titles_report = '/relatorio-titulos',
	result_titles_report = '/relatorio-titulos/resultado',
	product_report = '/relatorio-produtos',
	result_product_report = '/relatorio-produtos/resultado',
	order_buying_report = '/relatorio-entrada',
	result_order_buying_report = '/relatorio-entrada/resultado',
	order_selling_report = '/relatorio-saida',
	result_order_selling_report = '/relatorio-saida/resultado',
	stock_move_search = '/movimentacao-depositos',
	stock_move = '/movimentacao-depositos/resultado',

	stream_quiz_room = '/entidades/transmissao/:id'
}

enum ListRoutes {
	entities = '/entidades',
	resources = '/recursos',
	products = '/produtos',
	clients = '/clientes',
	deposits = '/depositos',
	bookkeeping = '/locais-de-escrituracao',
	stablishment = '/estabelecimentos',
	providers = '/fornecedores',
	buyers = '/compradores',
	seller = '/vendedores',
	services = '/servicos',
	uf = '/unidades-federativas',
	city = '/municipios',
	price_rule = '/regras-de-precificacao',
	core_rule = '/regras-de-nucleo',
	class_rule = '/regras-de-classe',
	cfop_rule = '/regras-de-cfop',
	tax_rule = '/regras-fiscais',
	comission_rule = '/regras-de-comissao',
	payment_conditions = '/condicoes-de-pagamento',
	document_types = '/tipos-de-documentos',
	shipping_types = '/tipos-de-frete',
	origin = '/procedencias',
	cfop = '/cfop',
	unit_measures = '/unidades-de-medidas',
	colors = '/cores',
	sizes = '/tamanhos',
	selling_order = '/pedidos-de-saida',
	pull_order = '/baixa-de-pedido',
	buying_order = '/pedidos-de-entrada',
	purchase_service_order = '/pedidos-de-compra-de-servico',
	selling_service_order = '/pedidos-de-venda-de-servico',
	calc_base = '/bases-de-calculo',
	card_flag = '/bandeiras-de-cartao',
	banks = '/bancos',
	employees = '/funcionarios',
	tax_codes = '/codigos-de-tributacao',
	stock_move = '/movimentacao-depositos',
	cost_center = '/centro-de-custo',
	billing_portfolio = '/carteiras-de-cobranca',
	expenses = '/despesas',
	recipes = '/receitas',
	availables = '/disponiveis',
	carriers = '/transportadoras',
	ncm = '/ncm',
	batches = '/lotes',
	brand_vehicle = '/marcas-veiculo',
	model_vehicle = '/modelos-veiculo',
	supply_vehicle = '/abastecimentos-veiculo',
	conductor = '/motoristas',
	vehicle = '/veiculos',
	fuel_types = '/tipos-de-combustivel',
	holidays = '/feriados',
	questions = '/perguntas',
	answers = '/respostas',
	modalities = '/modalidades',
	contact = '/fale-conosco',
	users = '/usuarios'
}

enum InsertRoutes {
	insert_entities = '/entidades/inserir',
	insert_buyers = '/compradores/inserir',
	insert_resources = '/recursos/inserir',
	insert_products = '/produtos/inserir',
	insert_clients = '/clientes/inserir',
	insert_deposits = '/depositos/inserir',
	insert_bookkeeping = '/locais-de-escrituracao/inserir',
	insert_stablishment = '/estabelecimentos/inserir',
	insert_providers = '/fornecedores/inserir',
	insert_seller = '/vendedores/inserir',
	insert_services = '/servicos/inserir',
	insert_uf = '/unidades-federativas/inserir',
	insert_city = '/municipios/inserir',
	insert_price_rule = '/regras-de-precificacao/inserir',
	insert_core_rule = '/regras-de-nucleo/inserir',
	insert_class_rule = '/regras-de-classe/inserir',
	insert_cfop_rule = '/regras-de-cfop/inserir',
	insert_tax_rule = '/regras-fiscais/inserir',
	insert_payment_conditions = '/condicoes-de-pagamento/inserir',
	insert_document_types = '/tipos-de-documentos/inserir',
	insert_shipping_types = '/tipos-de-frete/inserir',
	insert_origin = '/procedencias/inserir',
	insert_cfop = '/cfop/inserir',
	insert_unit_measures = '/unidades-de-medidas/inserir',
	insert_comission_rule = '/regras-de-comissao/inserir',
	insert_colors = '/cores/inserir',
	insert_sizes = '/tamanhos/inserir',
	insert_selling_order = '/pedidos-de-saida/inserir',
	insert_buying_order = '/pedidos-de-entrada/inserir',
	insert_purchase_service_order = '/pedidos-de-compra-de-servico/inserir',
	insert_selling_service_order = '/pedidos-de-venda-de-servico/inserir',
	insert_calc_base = '/bases-de-calculo/inserir',
	insert_card_flag = '/bandeiras-de-cartao/inserir',
	insert_banks = '/bancos/inserir',
	insert_employees = '/funcionarios/inserir',
	insert_tax_codes = '/codigos-de-tributacao/inserir',
	insert_stock_move = '/movimentacao-depositos/inserir',
	insert_cost_center = '/centro-de-custo/inserir',
	insert_billing_portfolio = '/carteiras-de-cobranca/inserir',
	insert_expenses = '/despesas/inserir',
	insert_recipes = '/receitas/inserir',
	insert_availables = '/disponiveis/inserir',
	insert_carriers = '/transportadoras/inserir',
	insert_request = '/requisicoes/inserir',
	insert_ncm = '/ncm/inserir',
	insert_batches = '/lotes/inserir',
	insert_pull_order = '/baixa-de-pedido/inserir',
	insert_brand_vehicle = '/marcas-veiculo/inserir',
	insert_model_vehicle = '/modelos-veiculo/inserir',
	insert_supply_vehicle = '/abastecimentos-veiculo/inserir',
	insert_conductor = '/motoristas/inserir',
	insert_vehicle = '/veiculos/inserir',
	insert_fuel_types = '/tipos-de-combustivel/inserir',
	insert_holidays = '/feriados/inserir',
	insert_questions = '/perguntas/inserir',
	insert_answers = '/respostas/inserir',
	insert_modalities = '/modalidades/inserir',
	insert_contact = '/fale-conosco/inserir',
	insert_users = '/usuarios/inserir',
}

enum EditRoutes {
	edit_entities = '/entidades/editar/:id',
	edit_buyers = '/compradores/editar/:id',
	edit_resources = '/recursos/editar/:id',
	edit_products = '/produtos/editar/:id',
	edit_clients = '/clientes/editar/:id',
	edit_deposits = '/depositos/editar/:id',
	edit_bookkeeping = '/locais-de-escrituracao/editar/:id',
	edit_stablishment = '/estabelecimentos/editar/:id',
	edit_providers = '/fornecedores/editar/:id',
	edit_seller = '/vendedores/editar/:id',
	edit_services = '/servicos/editar/:id',
	edit_uf = '/unidades-federativas/editar/:id',
	edit_city = '/municipios/editar/:id',
	edit_price_rule = '/regras-de-precificacao/editar/:id',
	edit_core_rule = '/regras-de-nucleo/editar/:id',
	edit_cfop_rule = '/regras-de-cfop/editar/:id',
	edit_class_rule = '/regras-de-classe/editar/:id',
	edit_tax_rule = '/regras-fiscais/editar/:id',
	edit_payment_conditions = '/condicoes-de-pagamento/editar/:id',
	edit_document_types = '/tipos-de-documentos/editar/:id',
	edit_shipping_types = '/tipos-de-frete/editar/:id',
	edit_origin = '/procedencias/editar/:id',
	edit_cfop = '/cfop/editar/:id',
	edit_unit_measures = '/unidades-de-medidas/editar/:id',
	edit_comission_rule = '/regras-de-comissao/editar/:id',
	edit_colors = '/cores/editar/:id',
	edit_sizes = '/tamanhos/editar/:id',
	edit_selling_order = '/pedidos-de-saida/editar/:id',
	edit_buying_order = '/pedidos-de-entrada/editar/:id',
	edit_purchase_service_order = '/pedidos-de-compra-de-servico/editar/:id',
	edit_selling_service_order = '/pedidos-de-venda-de-servico/editar/:id',
	edit_calc_base = '/bases-de-calculo/editar/:id',
	edit_card_flag = '/bandeiras-de-cartao/editar/:id',
	edit_banks = '/bancos/editar/:id',
	edit_employees = '/funcionarios/editar/:id',
	edit_tax_codes = '/codigos-de-tributacao/editar/:id',
	edit_stock_move = '/movimentacao-depositos/editar/:id',
	edit_cost_center = '/centro-de-custo/editar/:id',
	edit_billing_portfolio = '/carteiras-de-cobranca/editar/:id',
	edit_expenses = '/despesas/editar/:id',
	edit_recipes = '/receitas/editar/:id',
	edit_availables = '/disponiveis/editar/:id',
	edit_carriers = '/transportadoras/editar/:id',
	edit_request = '/requisicoes/editar/',
	edit_ncm = '/ncm/editar/:id',
	edit_batches = '/lotes/editar/:id',
	edit_pull_order = '/baixa-de-pedido/editar/:id',
	edit_brand_vehicle = '/marcas-veiculo/editar/:id',
	edit_model_vehicle = '/modelos-veiculo/editar/:id',
	edit_supply_vehicle = '/abastecimentos-veiculo/editar/:id',
	edit_conductor = '/motoristas/editar/:id',
	edit_vehicle = '/veiculos/editar/:id',
	edit_fuel_types = '/tipos-de-combustivel/editar/:id',
	edit_holidays = '/feriados/editar/:id',
	edit_questions = '/perguntas/editar/:id',
	edit_answers = '/respostas/editar/:id',
	edit_modalities = '/modalidades/editar/:id',
	edit_contact = '/fale-conosco/editar/:id',
	edit_users = '/usuarios/editar/:id'
}

export {
    AuthRoutes,
    ListRoutes,
    InsertRoutes,
    EditRoutes
}