import styled from "styled-components";
import ButtonLayout from '../../components/Button';

interface IMessage {
	myUser?: boolean;
	color?: string;
}

export const StreamContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h2 {
		font-size: 1.2rem;
		text-align: center;
		text-decoration: underline;
		width: 100%;
	}
`;

export const DivMain = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
`;

export const DivTop = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	button {
		background-color: var(--primary);
		border-radius: 8px;
		color: var(--white);
		cursor: pointer;
		padding: 6px 10px;
		margin-left: 4px;
	}
`;

export const Video = styled.div`
	display: flex;
	width: 100%;
	video {
		border: 1px solid red;
		width: 50%;
		height: 300px;
	}

`;

export const DivQuestion = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid var(--muted);
	height: 300px;
	width: 50%;

	/* & > div {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	} */

	& > div:nth-of-type(1) {
		display: flex;
		justify-content: flex-end;
		margin-right: 10px;

		p{
			color: var(--warning);
			font-weight: 700;
		}
	}
	& > div:nth-of-type(2){
		display: flex;
		overflow-x: auto;
		height: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		padding: 6px;
		width: 90%;

	}
`;

export const DivQuestionsBottom = styled.div`
	border: 1px solid var(--warning);
	display: flex;
	flex-direction: column;
	height: 100%;

	& > div {
		display: flex;
		overflow-x: auto;
		height: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 6px;
	}
`;

export const DivChat = styled.div`
	border: 1px solid var(--primary-darker);
	background-color: var(--menu-background);
	display: flex;
	flex-direction: column;
	height: 80vh;
	width: 30%;

	h2 {
		color: var(--gray);
	}
`;

export const DivMessages = styled.div<IMessage>`
	display: flex;
	flex-direction: column;
	margin-top: 4px;
	margin-bottom: 6px;
	overflow-y: auto;
`;

export const Message = styled.div<IMessage>`
	display: flex;
	flex-direction: column;
	text-align: ${(props: any) => props.myUser ? "end" : "start"};
	margin-top: 10px;
	padding: 0 6px;
	h5 {
		color: ${(props: any) => props.color ? props.color : ""};
	}
	p{
		color: var(--gray);
	}
`;

export const CardQuestion = styled.div`
	border: 4px solid var(--primary-lighter);
	background-color: var(--primary);
	border-radius: 10px;
	color: var(--white);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin: 2px;
	padding: 10px;
	min-height: fit-content;
	min-width: 200px;
	transition: ease-in-out .2s;
	
	label {
		color: var(--background);
	}
	span{
		font-weight: 800;
	}
	
	&:hover {
		box-shadow: 0 0 8px 2px var(--primary-lighter);
	}

	&:active {
		box-shadow: 0 0 8px 2px var(--primary-lighter);
	}
`;

export const DivRow = styled.div`
	display: flex;
`;

export const Button = styled(ButtonLayout)`
  width: 230px;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 6px;
`;